export const PAGE_TITLE = {
  COPPER: {
    title: 'Great news! You qualify for Frontier Internet',
    subtitle: '',
  },
  FIBER: {
    title: 'Don’t wait for better internet.',
    subtitle: 'Your home is in a fiber-optic service area. You’re eligible for our exclusive offer.',
  },
};

const fiberObtic = {
  subhead: '100% fiber-optic network',
  description: 'Tap into our 100% fiber-optic network and experience upload speeds up to 125x faster than cable.',
  icon: 'fiber',
};

const noDataCaps = {
  subhead: 'No data caps or overage fees',
  description: `We’ll never place limits on your internet access or charge you extra for it. So go ahead, embrace the unstoppable.`,
  icon: 'coin',
};

const support = {
  subhead: 'Support more devices reliably',
  description: 'With 99.9% overall network reliability, fiber is made for a connected home with multiple devices.',
  icon: 'laptopopen',
};

const fiberFeatures = [fiberObtic, noDataCaps, support];

export const BROADBAND_CONSUMER_LABEL_URL = {
  development: 'https://qat02.frontier.com/pages/consumerlabels-label',
  production: ' https://frontier.com/pages/consumerlabels-label',
};

export const features = {
  fiber: fiberFeatures,
  copper: [
    {
      subhead: 'No data caps or overage fees',
      description:
        'We’ll never place limits on your internet access or charge you extra for it. So go ahead, embrace the unstoppable.',
      icon: 'lineChart',
    },
    {
      subhead: 'High Speed Internet',
      description: 'Frontier internet uses the latest technologies for a network-ready connection to your home.',
      icon: 'lightningBolt',
    },
    {
      subhead: 'Exclusive plan perks',
      description:
        'Save cash each month when you establish and maintain Auto Pay. Plus, get a free state-of-the-art Wi-Fi router as early as tomorrow.',
      icon: 'infinite',
    },
  ],
  copperToFiber: fiberFeatures,
};

export const PLAN_SPEEDS_IDS = {
  '200': 'd03c8fa0-4464-4ee7-a979-0700e2f5d290',
  '500': 'c75fb0df-6d48-4a06-bb10-3ee47a8d3d28',
  '1000': '2b09989e-1696-4cc2-b13c-7e0950b74588',
  '2000': '67e4d13f-65f8-485b-8ebc-9c922e72c1a2',
  '5000': '4a7c0a86-9b8d-4b5f-b60f-10849155f5c0',
  '7000': '4fbc7f33-616d-4923-bbb7-e88e46658917',
};

export const PLAN_SPEEDS = ['200', '500', '1000', '2000', '5000'];

export const PLAN_SPEEDS_VRC_EVENT_NAME: { [key: string]: string } = {
  '200': 'fiber200',
  '500': 'fiber500',
  '1000': 'fiber1gig',
  '2000': 'fiber2gig',
  '5000': 'fiber5gig',
  '7000': 'fiber7gig',
};
